import React, { Component } from 'react'

const sign_in_style = {
  'textAlign': 'center',
  'float': 'right',
  'outline': 'none !important',
  'box-shadow': 'none',
}

class LogoutButton extends Component {
  render() {
    return (
      <>

        <div style={sign_in_style}>
          {/* random link */}
          <a href='https://lospec.com/palette-list/cherry-bomb-6' className='btn btn-outline' style={sign_in_style} >
            <p style={{ 'fontWeight': 'bold' }}>[ random ]</p>
          </a>
        </div>

      </>
    )
  }
}

export default LogoutButton
