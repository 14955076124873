import React from 'react';

import GridItem from '../components/GridItem.js';
import StemItems from '../components/StemItems.js';

import FluidGrid from '@allpro/react-fluid-grid';

function Home() {
    return (
        <>
            <FluidGrid style={{ 'text-align': 'center' }} container>

                <GridItem />

                <StemItems />

            </FluidGrid>
            
        </>
        
    );
}

export default Home;