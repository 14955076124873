import React from 'react';

import { FaHeart } from 'react-icons/fa';

  
function FloodStuff() {
    return (
        <>
            <div className="row" style={{'text-align': 'left', 'padding': '2%'}}>
                <div className="col-lg-6 col-md-6 col-sm-12" >
                    <h4 style={{'text-align': 'left',}}>
                        Thank you to everyone who has shared and donated to the <b><u><a href="https://www.gofundme.com/f/family-house-flooded-in-historic-rainstorm-fl">gofundme</a></u></b>, 
                        this is what you've helped us with so far:
                    </h4>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 dashed" >
                
                    <div className="row">
                        <div className="col-6">
                            <h5><b>Total Raised</b> (to date)</h5>
                            <h5>$4,767</h5>
                        </div>        

                        <div className="col-6">
                            <h5><b>Total Transfered</b> (after fees)</h5>
                            <h5>$4,320.32</h5>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-sm-12">
                    <div className="table-responsive">
                        
                        <table class="table">
                            <thead>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col"></th>
                                <th scope="col">Cost</th>
                                <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <th scope="row">1</th>
                                <td>Car tow to mechanic, 4.7 miles.</td>
                                <td>$175</td>
                                <td>Tuesday, 04|18|2023</td>
                                </tr>
                                
                                <tr>
                                <th scope="row">2</th>
                                <td>Wet phone repair/upgrade. </td>
                                <td>$271.73</td>
                                <td>Tuesday, 04|18|2023</td>
                                </tr>

                                <tr>
                                <th scope="row">3</th>
                                <td>Mom's gas. </td>
                                <td>$34.60</td>
                                <td>Tuesday, 04|18|2023</td>
                                </tr>

                                

                                <tr>
                                <th scope="row">5</th>
                                <td>Two+ weeks groceries. </td>
                                <td>$269.84</td>
                                <td>Wednesday, 04|19|2023</td>
                                </tr>

                                <tr>
                                <th scope="row">4</th>
                                <td>Sirius flea treatment. </td>
                                <td>$20</td>
                                <td>Thursday, 04|20|2023</td>
                                </tr>

                                <tr>
                                <th scope="row">6</th>
                                <td>Interior treatment/sanitization x2 cars. </td>
                                <td>$385</td>
                                <td>Thursday, 04|20|2023</td>
                                </tr>
                                
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td/>
                                    <th scope="col">Total Used:</th>
                                    <td><b>$1,156.17</b></td>
                                    <td/>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

            <FaHeart /><FaHeart /><FaHeart /><FaHeart /><FaHeart /><FaHeart />

            <br></br>
            <br></br>
            <br></br>

            <p><i>Some of our inital goals for this fundraiser have changed as we've had to respond. Click the gofundme link above to see more. </i></p>

        </>
        
    );
}

export default FloodStuff;