import React, { Component } from 'react'
import FluidGrid from '@allpro/react-fluid-grid'
import ReactSoundCloud from 'react-soundcloud-embedded'



let unclicked_style = {
    item: true,
    // trial error for iphone to show 1 playlist
    flexBasis: '390px',
    minWidth: '33%',
    maxWidth: '80%',
    // 'marginLeft': 'auto',
    // 'marginRight': 'auto'
    margin: 'auto' //comment this for neat align
}

class GridItem extends Component {
    render() {
        return (
            <>
                <FluidGrid style={unclicked_style}>
                    <ReactSoundCloud url='soundcloud.com/gun-go' />
                </FluidGrid>

                <div style={{ 'text-align': 'left', 'padding': '2%' }}>
                    <h2>HEREAREMYBOPS
                        <br />
                        <h4>YOUREWELCOMETO</h4>
                        <h5>LISTEN - BYE</h5>
                    </h2>
                </div>

            </>

        )

    }

}


export default GridItem
