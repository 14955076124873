import React, { Component } from 'react'
import StemPlayer from './StemPlayer.js'
import FluidGrid from '@allpro/react-fluid-grid'

// Import audio files but just their path by default.
function importAll(r) {
    return r.keys().map(r).map(value => value['default']);
}

let unclicked_style = {
    item: true,
    // trial error for iphone to show 1 playlist
    flexBasis: '390px',
    minWidth: '80%',
    maxWidth: '100%',
    marginTop: '4%',
    margin: 'auto' //comment this for neat align
}

class StemItems extends Component {
    render() {

        let stems2 = {
            audios: importAll(require.context('../media/stems02', false, /\.(wav|mp3)$/)),
            track: "Wrong Again redux"
        }

        let stems3 = {
            audios: importAll(require.context('../media/bruised_slightly_stems', false, /\.(wav|mp3)$/)),
            track: "bruised slightly (v.5)"
        }

        let stems6 = {
            audios: importAll(require.context('../media/softly_lawls_stems', false, /\.(wav|mp3)$/)),
            track: "The World Softly Lawls"
        }

        let stems7 = {
            audios: importAll(require.context('../media/la_swing_stems', false, /\.(wav|mp3)$/)),
            track: "la swing"
        }

        let stems8 = {
            audios: importAll(require.context('../media/oo_la_chant_stems', false, /\.(wav|mp3)$/)),
            track: "oo wa uu chant like good times"
        }

        let stems9 = {
            audios: importAll(require.context('../media/emily_test_stems', false, /\.(wav|mp3)$/)),
            track: "emily | vile mentality | troll"
        }


        return (
            <>
                <FluidGrid style={unclicked_style}>

                    <StemPlayer current_track={stems8.track} audios={stems8.audios} />
                    <br />
                    <StemPlayer current_track={stems6.track} audios={stems6.audios} />
                    <br />
                    <StemPlayer current_track={stems7.track} audios={stems7.audios} />
                    <br />
                    <StemPlayer current_track={stems3.track} audios={stems3.audios} />
                    <br />
                    <StemPlayer current_track={stems2.track} audios={stems2.audios} />
                    <br />
                    <StemPlayer current_track={stems9.track} audios={stems9.audios} />
                    <br />


                </FluidGrid>

            </>

        )

    }

}


export default StemItems
