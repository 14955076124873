import React, { Component } from 'react'

const sign_in_style = {
    'textAlign': 'center',
    'float': 'right',
    'outline': 'none !important',
    'box-shadow': 'none',
}

class BasicButton extends Component {
    render() {
        return (

            <>

                <div style={sign_in_style}>
                    {/* random link */}
                    <a href={this.props.href} className='btn btn-outline' style={sign_in_style} >
                        <p style={{ 'fontWeight': 'bold' }}>[ {this.props.text} ]</p>
                    </a>
                </div>

            </>

        )
    }
}

export default BasicButton
