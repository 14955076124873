import React, { Component } from 'react'

import Header from './components/Header.js'
import Filter from './components/Filter.js';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout.js";
import Home from "./pages/Home.js";
import FloodStuff from "./pages/FloodStuff.js";
import NoPage from "./pages/NoPage.js";

import './App.css'



class App extends Component {
  constructor() {
    super()

    this.state = {
      filter_string: '',
    }

  }

  setData() {

  }

  componentDidMount() {

  }

  render() {

    return (
      <>



        <div id="app" className="App">
          <div>
            <Header data={this.state} />

            <Filter onTextChange={text => {
                      this.setState({ filter_string: text })
                    }} />  
            

            

            <BrowserRouter>
             
                <Routes>  
                  <Route path="/" element={<Layout />}>  
                       
                    <Route index element={<Home />} />
                    <Route path="floodstuff" element={<FloodStuff />} />
                    <Route path="*" element={<NoPage />} />
                  </Route>
                </Routes>
            </BrowserRouter>
            

          </div>

        </div>

      </>
    )
  }
}

export default App
