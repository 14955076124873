import React, { Component } from 'react'
import LogoutButton from './LogoutButton.js'
import ToggleButton from './ToggleButton.js'
import PlaylistPunchButton from './PlaylistPunchButton.js'
import BasicButton from './BasicButton.js'


class Header extends Component {
    render() {

        return (
            <>

                <div>
                    {/* Button for random site. */}
                    <BasicButton text='random' href='https://twitter.com/_rangerbts_/status/1541995729324871680?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Eprofile%3Abeepboxco%7Ctwgr%5EeyJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9mZiIsInZlcnNpb24iOm51bGx9LCJ0ZndfcmVmc3JjX3Nlc3Npb24iOnsiYnVja2V0Ijoib2ZmIiwidmVyc2lvbiI6bnVsbH0sInRmd190d2VldF9yZXN1bHRfbWlncmF0aW9uXzEzOTc5Ijp7ImJ1Y2tldCI6InR3ZWV0X3Jlc3VsdCIsInZlcnNpb24iOm51bGx9LCJ0Zndfc2Vuc2l0aXZlX21lZGlhX2ludGVyc3RpdGlhbF8xMzk2MyI6eyJidWNrZXQiOiJpbnRlcnN0aXRpYWwiLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2V4cGVyaW1lbnRzX2Nvb2tpZV9leHBpcmF0aW9uIjp7ImJ1Y2tldCI6MTIwOTYwMCwidmVyc2lvbiI6bnVsbH0sInRmd191c2VyX2ZvbGxvd19pbnRlbnRfMTQ0MDYiOnsiYnVja2V0IjoiZm9sbG93IiwidmVyc2lvbiI6bnVsbH0sInRmd190d2VldF9lZGl0X2Zyb250ZW5kIjp7ImJ1Y2tldCI6Im9mZiIsInZlcnNpb24iOm51bGx9fQ%3D%3D&ref_url=https%3A%2F%2Fwww.beepbox.co%2F' />
                    <h1><u>gungo.dev</u>| <i> music & software development  </i></h1>

                    <ToggleButton />

                    {/* Button for Playlist Punch 2.0. */}
                    <BasicButton text='playlistpunch' href='https://playlistpunch.gungo.dev' />
                </div>

            </>

        )
    }
}

export default Header
