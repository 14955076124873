import React, { Component, useState, useEffect } from 'react'
import { HiHeart, HiOutlineHeart } from 'react-icons/hi'
import { MdAllInbox, MdShuffle, MdShuffleOn } from 'react-icons/md'
import FullControl from './FullControl.js'
import ReactHowler from 'react-howler'
import raf from 'raf' // requestAnimationFrame polyfill


const minimal_style = {
    'outline': 'none ',
    'box-shadow': 'none',
    'border': 'none ',
}


class StemPlayer extends Component {
    constructor(props) {
        super(props)

        // need reference of each stem component for later use.
        this.stem_ref_list = []

        this.state = {
            playing: false,
            loaded: false,
            loop: false,
            mute: false,
            volume: 0.5,
            seek: 0.0,
            rate: 1,
            isSeeking: false,
            // misc.
            shuffle: false,
            current_track_loved: false
        }

        this.handleToggle = this.handleToggle.bind(this)
        this.handleOnLoad = this.handleOnLoad.bind(this)
        this.handleOnEnd = this.handleOnEnd.bind(this)
        this.handleOnPlay = this.handleOnPlay.bind(this)
        this.handleStop = this.handleStop.bind(this)
        this.renderSeekPos = this.renderSeekPos.bind(this)
        this.handleLoopToggle = this.handleLoopToggle.bind(this)
        this.handleMuteToggle = this.handleMuteToggle.bind(this)
        this.handleMouseDownSeek = this.handleMouseDownSeek.bind(this)
        this.handleMouseUpSeek = this.handleMouseUpSeek.bind(this)
        this.handleSeekingChange = this.handleSeekingChange.bind(this)
        this.handleVolume = this.handleVolume.bind(this)
        this.handleRate = this.handleRate.bind(this)
        //misc
        this.love_track = this.love_track.bind(this)
        this.unlove_track = this.unlove_track.bind(this)
        this.toggle_shuffle = this.toggle_shuffle.bind(this)

    }

    componentWillUnmount() {
        this.clearRAF()
    }

    // handle toggle of all stems
    handleToggle() {
        // set toggle for this stemplayer
        this.setState({
            playing: !this.state.playing
        })

        this.stem_ref_list.map((stem) =>
            stem.handleToggle()
        )

        this.handleOnLoad()

    }

    handleOnLoad() {
        console.log('load', this.stem_ref_list)
        this.setState({
            loaded: true,
            duration: this.stem_ref_list[0].state.duration
            // duration: this.stem_ref_list[0].duration()
        })

        this.renderSeekPos()
    }

    // handle play of this stemplayer
    handleOnPlay() {
        this.setState({
            playing: true
        })

        this.renderSeekPos()
    }

    handleOnEnd() {
        this.stem_ref_list.map((stem) =>
            stem.handleOnEnd()
        )

        this.setState({
            playing: false
        })
        this.clearRAF()
    }


    // handle stop of all stems
    handleStop() {
        // set stop for this stemplayer
        this.setState({
            playing: false
        })

        this.stem_ref_list.map((stem) =>
            stem.handleStop()
        )
        // render seeking position
        this.renderSeekPos()

    }


    handleLoopToggle() {
        this.setState({
            loop: !this.state.loop
        })

        this.stem_ref_list.map((stem) =>
            stem.handleLoopToggle()
        )
    }

    handleMuteToggle() {
        this.setState({
            mute: !this.state.mute
        })

        this.stem_ref_list.map((stem) =>
            stem.handleMuteToggle()
        )
    }

    handleMouseDownSeek() {
        this.setState({
            isSeeking: true
        })

        this.stem_ref_list.map((stem) =>
            stem.handleMouseDownSeek()
        )
    }

    handleMouseUpSeek(e) {
        this.setState({
            isSeeking: false
        })

        this.stem_ref_list.map((stem) =>
            stem.handleMouseUpSeek(e)
        )
    }

    handleSeekingChange(e) {
        this.setState({
            seek: parseFloat(e.target.value)
        })

        this.stem_ref_list.map((stem) =>
            stem.handleSeekingChange(e)
        )
    }

    // set render pos of this stemplayer
    renderSeekPos() {
        if (!this.state.isSeeking) {
            // use first sample from list to match
            this.setState({
                seek: this.stem_ref_list[0].state.seek
            })
        }
        if (this.state.playing) {
            this._raf = raf(this.renderSeekPos)
        }
    }

    // handle volume of all stems
    handleVolume(e) {
        const new_volume = parseFloat(e.target.value)

        // set stop for this stemplayer
        this.setState({
            volume: new_volume
        })

        this.stem_ref_list.map((stem) =>
            stem.setState({ volume: new_volume })
        )
    }

    // handle rate of all stems
    handleRate(e) {
        const new_rate = parseFloat(e.target.value)
        // set stop for this stemplayer
        this.setState({
            rate: new_rate
        })

        this.stem_ref_list.map((stem) =>
            stem.handleRate(e)
        )
    }

    clearRAF() {
        raf.cancel(this._raf)
    }


    // toggles shuffle playback.
    toggle_shuffle() {
        console.log("Toggle shuffle ><")

        this.setState({
            shuffle: !this.state.shuffle
        })

    }

    // changes heart icon to love status.
    love_track(track) {
        console.log("Loving track: ", track)

        this.setState({
            current_track_loved: true
        })

    }

    //changes heart icon to unlove status.
    unlove_track(track) {
        console.log("Unloving track: ", track)

        this.setState({
            current_track_loved: false
        })
    }


    render() {

        return (
            <>
                <div className='players'>
                    <section className='row' style={{ 'flex': '100%', 'paddingBottom': '2%' }}>

                        {/* controls */}
                        <div className='col-sm-3 ms-auto' >
                            <div style={{ 'textAlign': 'right', 'paddingLeft': '5px' }}>
                                <h3>[ controls ]</h3>

                                {/* playback */}
                                <div style={{ 'textAlign': 'right', 'paddingBottom': '4%' }}>
                                    <button className='btn' style={minimal_style} onClick={this.handleStop} >
                                        &lt;&lt;
                                    </button>
                                    <button className='btn' style={minimal_style} onClick={this.handleToggle} >
                                        <b>{this.state.playing ? '[  PAUSE]  ]' : '[  PLAY  ]'}</b>
                                    </button>
                                    <button className='btn' style={minimal_style} onClick={() => { }} >
                                        &gt;&gt;
                                    </button>
                                </div>

                                {/* all stem settlings */}
                                <section style={{ 'border': '2px', 'border-style': 'solid dotted none none' }}>
                                    <p><i>~) <b>mess with all stems</b></i></p>

                                    <div className='toggles'>
                                        <label>
                                            [ loop ]
                                            <br />
                                            <input
                                                type='checkbox'
                                                checked={this.state.loop}
                                                onChange={this.handleLoopToggle}
                                            />
                                        </label>

                                        <label>
                                            [ mute ]
                                            <br />
                                            <input
                                                type='checkbox'
                                                checked={this.state.mute}
                                                onChange={this.handleMuteToggle}
                                            />
                                        </label>
                                    </div>

                                    <div className='seek'>
                                        <label>
                                            {this.state.seek.toFixed(2)}

                                            {' / '}
                                            {(this.state.duration) ? this.state.duration.toFixed(2) : 'NaN'}
                                            <br />
                                            <span className='slider-container'>
                                                <input
                                                    type='range'
                                                    min='0'
                                                    max={this.state.duration ? this.state.duration.toFixed(2) : 0}
                                                    step='.01'
                                                    value={this.state.seek}
                                                    onChange={this.handleSeekingChange}
                                                    onMouseDown={this.handleMouseDownSeek}
                                                    onMouseUp={this.handleMouseUpSeek}
                                                />
                                            </span>
                                        </label>
                                    </div>

                                    <div className='volume'>
                                        <label>
                                            [ volume ]
                                            <br />
                                            <span className='slider-container'>
                                                <input
                                                    type='range'
                                                    min='0'
                                                    max='1'
                                                    step='.02'
                                                    value={this.state.volume}
                                                    onChange={this.handleVolume}
                                                />
                                            </span>
                                            <br />
                                            {this.state.volume.toFixed(2)}
                                        </label>
                                    </div>

                                    <div className="rate">
                                        <label>
                                            [  rate ]
                                            <br />
                                            <span className='slider-container'>
                                                <input
                                                    type='range'
                                                    min='0.1'
                                                    max='3'
                                                    step='.01'
                                                    value={this.state.rate}
                                                    onChange={this.handleRate}
                                                />
                                            </span>
                                            <br />
                                            {this.state.rate.toFixed(2)}
                                        </label>
                                    </div>

                                </section>

                                <br />
                                {/* heart */}
                                <div style={{ 'paddingTop': '5%' }}>
                                    <a>
                                        {(this.state.current_track_loved == true)
                                            ? <HiHeart size={24} onClick={() => { this.unlove_track(this.props.current_track) }} />
                                            : <HiOutlineHeart size={24} onClick={() => { this.love_track(this.props.current_track) }} />}
                                    </a>
                                </div>

                                {/* shuffle */}
                                <div style={{ 'paddingTop': '4%' }} onClick={() => { this.toggle_shuffle(this.state.shuffle) }}>
                                    <a>
                                        {(this.state.shuffle == true)
                                            ? <MdShuffleOn size={24} />
                                            : <MdShuffle size={24} />}
                                    </a>
                                </div>

                            </div>
                        </div>


                        {/* song / stems */}
                        <div className='col-sm-9 ms-auto' style={{ 'align': 'left' }} >
                            <div style={{ 'textAlign': 'left', 'paddingLeft': '2%' }}>
                                <h3 style={{ 'paddingBottom': '2px' }}>[ song / stems ]</h3>
                                <h4 style={{ 'paddingLeft': '10.5' }}><i>-) <u>{
                                    this.props.current_track
                                }</u></i></h4>
                            </div>

                            <div className='container' style={{ 'max-height': '500px', 'overflow-y': 'auto' }}>
                                <div className='players'  >
                                    {   /* stems */

                                        this.props.audios.map((audio, index) =>

                                            <section style={{ 'border': '2px', 'border-style': 'none none solid dotted' }}>
                                                <h5>{audio.substring(14, audio.indexOf('.'))}</h5>
                                                {(audio.substring(14, audio.indexOf('.')).length <= 16)
                                                    ? <div style={{ 'height': '1.8rem' }}></div>
                                                    : <></>
                                                }

                                                <FullControl src={audio} onLoad={this.handleOnLoad} ref={(node) => { this.stem_ref_list[index] = node }} />
                                            </section>

                                        )
                                    }
                                </div>
                            </div>

                        </div>
                    </section >
                </div >
            </>
        )
    }

}

export default StemPlayer
