import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

import { FaMapSigns } from 'react-icons/fa';

const nav_style = {
    'textAlign': 'center',
    'float': 'right',
    'outline': 'none !important',
    'box-shadow': 'none',
    'fontWeight': 'bold'
}

function Navbar() {
  return (
    <>
    
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                <ul className="navbar-nav d-flex flex-row">
            
                        <a className="btn btn-outline nav-link me-3 me-lg-0" href="/" style={nav_style}>
                            <FaMapSigns />
                        </a>
  

                        <Link to="/" className="btn btn-outline nav-link me-3 me-lg-0" style={nav_style} > [ fun stuff ] </Link>
                   
                        <Link to="floodstuff" className="btn btn-outline nav-link me-3 me-lg-0" style={nav_style} > [ flood stuff ] </Link>

                
                </ul>
            </div>
        </nav>
    
    <hr/>
    </>
  );
}

export default Navbar;